import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {AuthenticationService} from '../Shared-global/Services/authentication.service';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {AuthGuardAdmin} from '../Shared-global/Guards/auth-guard-admin';
import {AuthGuardUser} from '../Shared-global/Guards/auth-guard-user';
import {AuthInterceptor} from '../Shared-global/Interceptors/authentication.interceptor';

import {AppSidebarComponent} from './app-sidebar/app-sidebar.component';
import {AppTopbarComponent} from './app-topbar/app-topbar.component';
import {AppWelcomeComponent} from './app-welcome/app-welcome.component';

import { CommonModule } from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule } from 'ngx-toastr';
import { QRCodeModule } from 'angularx-qrcode';
import { DeviceDetectorService} from 'ngx-device-detector';
import { AppNavigationMobileComponent } from './app-navigation-mobile/app-navigation-mobile.component';
import { ReactiveFormsModule } from '@angular/forms';
import { ChartsModule } from 'ng2-charts';

@NgModule({
    declarations: [
        AppComponent,
        AppSidebarComponent,
        AppTopbarComponent,
        AppWelcomeComponent,
        AppNavigationMobileComponent
    ],
    imports: [
        ReactiveFormsModule,
        CommonModule,
        QRCodeModule,
        BrowserAnimationsModule,
        ToastrModule.forRoot(),
        NgbModule,
        BrowserModule,
        AppRoutingModule,
        HttpClientModule,
        ChartsModule
    ],
    providers: [AuthenticationService, AuthGuardAdmin, AuthGuardUser, DeviceDetectorService,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AuthInterceptor,
            multi: true
        }],
    bootstrap: [AppComponent]
})
export class AppModule {
}
