import { CanActivate, Router } from '@angular/router';
import { AuthenticationService } from '../Services/authentication.service';
import { Injectable } from '@angular/core';

@Injectable()
export class AuthGuardUser implements CanActivate {

    constructor(private router: Router, private authService: AuthenticationService) { }

    canActivate() {
        if (this.authService.isLoggedIn()) {
            return true;
        }
        this.authService.logout()
        this.router.navigate(['login'])

    }
}
