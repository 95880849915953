import {CanActivate, Router} from '@angular/router';
import {AuthenticationService} from '../Services/authentication.service';
import {Injectable} from '@angular/core';

@Injectable()
export class AuthGuardAdmin implements CanActivate {

  constructor(private router: Router, private authService: AuthenticationService) { }

  canActivate() {
    if (this.authService.isAdmin()) {
      return true;
    }

    alert('You are not an administrator.');
  }
}
