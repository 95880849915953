import { Injectable } from '@angular/core';
import {Observable} from 'rxjs';
import {environment} from '../../../environments/environment';
import {HttpClient} from '@angular/common/http';

import {ProductSearchResultModel} from '../models/product-search-result.model';
import {DeviceSearchResultModel} from '../models/device-search-result.model';
import {CustomerSearchResultModel} from '../models/customer-search-result.model';

@Injectable({
  providedIn: 'root'
})

export class SearchService {
    endpointUrl: string = `${environment.apiUrl}/search`

    constructor(private http: HttpClient) {}

    productSearch(search): Observable<ProductSearchResultModel[]> {
        let endpoint = `${this.endpointUrl}/products/` + search;
        return this.http.get<ProductSearchResultModel[]>(endpoint);
    }

    deviceSearch(search): Observable<DeviceSearchResultModel[]> {
        let endpoint = `${this.endpointUrl}/devices/` + search;
        return this.http.get<DeviceSearchResultModel[]>(endpoint);
    }

    customerSearch(search): Observable<CustomerSearchResultModel[]> {
        let endpoint = `${this.endpointUrl}/customers/` + search;
        return this.http.get<CustomerSearchResultModel[]>(endpoint);
    }
}
