import { Injectable } from '@angular/core';
import { tap } from 'rxjs/operators';
import {
    HttpRequest,
    HttpHandler,
    HttpEvent,
    HttpInterceptor
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { AuthenticationService } from '../Services/authentication.service';
import { Router } from '@angular/router';
import {ToastrService} from 'ngx-toastr';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
    constructor(private authService: AuthenticationService, private router: Router, private toast: ToastrService) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const token = this.authService.getToken();
        if (token) {
            request = request.clone({
                headers: request.headers.set('Authorization', this.authService.getToken())
            });
        }

        // TODO: Implement automatic renewal of token
        /*const timeUntilExpiry = this.authService.timeUntilExpiry();
        if (timeUntilExpiry < 600) {
            console.log('Login expires in less than ten minutes!');
        }*/

        return next.handle(request).pipe(
            tap(() => {}, error => {
                if (error.status === 401) {
                    if (this.authService.isExpired()) {
                        this.authService.logout();
                        this.router.navigate(['login']);
                    } else {
                        this.router.navigate(['']);
                        this.toast.warning('Dit login er udløbet. Login igen.');
                    }
                }
            })
        );
    }
}
