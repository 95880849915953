import {Component, OnInit} from '@angular/core';
import {NavigationEnd, Router} from '@angular/router';
import {AuthenticationService} from '../../Shared-global/Services/authentication.service';
import {environment} from '../../../environments/environment';
import {version} from '../../../environments/version';

@Component({
    selector: 'app-topbar',
    templateUrl: './app-topbar.component.html',
    styleUrls: ['./app-topbar.component.scss']
})
export class AppTopbarComponent implements OnInit {

    version : string = "";

    public pushRightClass: string;
    dev = false;

    constructor(private router: Router, public authenticationService: AuthenticationService) {
        this.version = version;
        if (!environment.production) {
            this.dev = true;
        }
        this.router.events.subscribe(val => {
            if (
                val instanceof NavigationEnd &&
                window.innerWidth <= 992 &&
                this.isToggled()
            ) {
                this.toggleSidebar();
            }
        });
    }

    ngOnInit() {
        this.pushRightClass = 'push-right';
        this.getEmail();
    }

    getEmail(): string {
        const currentUser = JSON.parse(localStorage.getItem('currentUser'));
        return currentUser && currentUser.email;
    }

    isToggled(): boolean {
        const dom: Element = document.querySelector('body');
        return dom.classList.contains(this.pushRightClass);
    }

    toggleSidebar() {
        const dom: any = document.querySelector('body');
        dom.classList.toggle(this.pushRightClass);
    }

    logOut() {
        this.authenticationService.logout();
        this.router.navigate(['/login']);
        location.reload();
    }

    stopImpersonate() {
        let custId = this.authenticationService.getUser().customer_id;
        let res = this.authenticationService.stopImpersonate();
        if (res) {
            this.router.navigate(['/customers/' + custId]);
        }
    }
}
