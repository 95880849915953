import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { AuthenticationService } from '../../Shared-global/Services/authentication.service';
import { UserService } from '../../Shared-global/Services/user.service';
import { SearchService } from '../../Shared-global/Services/search.service';
import { UserModel } from '../../customer/customer-shared/user.model';
import { CustomerSearchResultModel } from 'src/app/Shared-global/models/customer-search-result.model';
import { DeviceSearchResultModel } from 'src/app/Shared-global/models/device-search-result.model';
import { ProductSearchResultModel } from 'src/app/Shared-global/models/product-search-result.model';
import { FormControl, FormGroup } from '@angular/forms';

@Component({
    selector: 'app-navigation-mobile',
    templateUrl: './app-navigation-mobile.component.html',
    styleUrls: ['./app-navigation-mobile.component.scss']
})

export class AppNavigationMobileComponent implements OnInit {
    isActive: boolean;
    collapsed: boolean;
    showMenu: string;
    pushRightClass: string;
    user: UserModel;
    searchfield: string;
    menuvisible:boolean = false;

    searchCustomers: CustomerSearchResultModel[];
    searchDevices: DeviceSearchResultModel[];
    searchProducts: ProductSearchResultModel[];

    userForm = new FormGroup({
        search: new FormControl()
   });

    @Output() collapsedEvent = new EventEmitter<boolean>();

    constructor(
        public router: Router,
        public authService: AuthenticationService,
        public userService: UserService,
        public searchService: SearchService,
        ){
            this.router.events.subscribe(val => {
                if (
                    val instanceof NavigationEnd &&
                    window.innerWidth <= 992 &&
                    this.isToggled()
                ) {
                    this.toggleSidebar();
                }
            });
        }

    ngOnInit() {
        this.isActive = false;
        this.collapsed = false;
        this.showMenu = '';
        this.menuvisible = false;
        this.pushRightClass = 'push-right';
        this.user = this.authService.getUser()

        if (!this.user)
            return;
    }

    toggleMenu() {
        if(this.menuvisible) {
            this.menuvisible = false;
        } else {
            this.menuvisible = true;
        };
    }
    close() {
        this.searchfield = null;
        this.menuvisible = false;
    }
    search() {
        const search = this.userForm.get('search').value;

        if(search.length >= 3) {
            this.searchfield = search;
            this.searchCustomers = null;
            this.searchDevices = null;
            this.searchProducts = null;

            this.searchService.customerSearch(this.searchfield).subscribe(resp => {
                this.searchCustomers = resp;
            }, () => console.log("error"));

            this.searchService.deviceSearch(this.searchfield).subscribe(resp => {
                this.searchDevices = resp;
            }, () => console.log("error"));

            this.searchService.productSearch(this.searchfield).subscribe(resp => {
                this.searchProducts = resp;
            }, () => console.log("error"));
        } else {
            this.searchfield = null;
        }
    }

    eventCalled() {
        this.isActive = !this.isActive;
    }

    addExpandClass(element: any) {
        this.collapsed = false;
        this.collapsedEvent.emit(this.collapsed);
        if (element === this.showMenu) {
            this.showMenu = '0';
        } else {
            this.showMenu = element;
        }
    }

    toggleCollapsed() {
        this.collapsed = !this.collapsed;
        this.collapsedEvent.emit(this.collapsed);
    }

    isToggled(): boolean {
        const dom: Element = document.querySelector('body');
        return dom.classList.contains(this.pushRightClass);
    }

    toggleSidebar() {
        const dom: any = document.querySelector('body');
        dom.classList.toggle(this.pushRightClass);
    }

    logout() {
        this.searchfield = null;
        this.menuvisible = false;
        this.authService.logout();
        this.router.navigate(['/login']);
    }
    reloadComponent(self:boolean){
        const url="/options";

        this.router.navigateByUrl('/',{skipLocationChange:true}).then(()=>{
          this.router.navigate([`/${url}`])
        });
      }
}
