import { Injectable } from '@angular/core';
import {Observable} from 'rxjs';
import {environment} from '../../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {UserDisplayPermissionModel} from '../models/user-display-permission.model';
import {UserModel} from '../../customer/customer-shared/user.model';

@Injectable({
  providedIn: 'root'
})
export class UserService {
    endpointUrl: string = `${environment.apiUrl}/user`

    constructor(private http: HttpClient) {
    }

    getUserDisplayPermissions(user_id): Observable<UserDisplayPermissionModel> {
        let getUDPEndpoint = `${this.endpointUrl}/UserDisplayPermission/Get`;

        let params = {}
        params['user_id'] = user_id

        return this.http.get<UserDisplayPermissionModel>(getUDPEndpoint, {
            params
        });
    }

    updateUDP(udp: UserDisplayPermissionModel): Observable<any> {
        let changeLoyaltyUserEndpoint = `${this.endpointUrl}/UserDisplayPermission`;
        return this.http.put(changeLoyaltyUserEndpoint, udp);
    }

    getUser(userId: number): Observable<UserModel> {
        return this.http.get<UserModel>(this.endpointUrl + "/" + userId);
    }
}
