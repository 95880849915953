import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {environment} from '../../../environments/environment';
import {UserModel} from 'src/app/customer/customer-shared/user.model';
import {UserService} from './user.service';

@Injectable({
    providedIn: 'root'
})

export class AuthenticationService {

    currentUser: any = null;
    origUser: any = null;

    constructor(private http: HttpClient, private userService: UserService) {
        this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
    }

    getUser() {
        return this.currentUser;
    }

    isLoggedIn() {
        return this.currentUser;
    }

    timeUntilExpiry() {
        if (this.currentUser && this.currentUser.expires) {
            const ts = Math.round(new Date().getTime() / 1000);
            return this.currentUser.expires - ts;
        }
        return -1;
    }

    isExpired() {
        const ts = Math.round(new Date().getTime() / 1000);
        return this.currentUser && ts > this.currentUser.expires;
    }

    login(email: string, password: string): Observable<boolean> {
        return this.http.post<any>(environment.apiUrl + '/login/token', { email, password })
            .pipe(map(response => {
                const token = response.token;
                const expires = response.expires;
                // login successful if there's a jwt token in the response
                if (token) {
                    // store email and jwt token in local storage to keep user logged in between page refreshes
                    this.currentUser = { token, expires, ...response.user };
                    localStorage.setItem('currentUser', JSON.stringify(this.currentUser));
                    if (this.currentUser.role == 3) {
                        this.userService.getUserDisplayPermissions(this.currentUser.id).subscribe(udp => {
                            this.currentUser.udp = udp;
                        }, error => {
                            console.log(error);
                            this.currentUser.udp = {
                                id: undefined,
                                user_id: undefined,
                                coins_allowed: false,
                                loyalty_allowed: false,
                                cancelled_allowed: false,
                                mifare_allowed: false,
                                club_allowed: false
                            };
                        }, () => {
                            localStorage.setItem('currentUser', JSON.stringify(this.currentUser));
                            // return true to indicate successful login
                            return true;
                        });
                    } else {
                        localStorage.setItem('currentUser', JSON.stringify(this.currentUser));
                    }
                } else {
                    // return false to indicate failed login
                    return false;
                }
            }));
    }

    getLogin(email: string): Observable<boolean> {
        return this.http.post<any>(environment.apiUrl + '/login/gettoken', { email })
            .pipe(map(response => {
                const token = response.token;
                const expires = response.expires;
                // login successful if there's a jwt token in the response
                if (token) {
                    // store email and jwt token in local storage to keep user logged in between page refreshes
                    this.currentUser = { token, expires, ...response.user };
                    localStorage.setItem('currentUser', JSON.stringify(this.currentUser));
                    if (this.currentUser.role == 3) {
                        this.userService.getUserDisplayPermissions(this.currentUser.id).subscribe(udp => {
                            this.currentUser.udp = udp;
                        }, error => {
                            console.log(error);
                            this.currentUser.udp = {
                                id: undefined,
                                user_id: undefined,
                                coins_allowed: false,
                                loyalty_allowed: false,
                                cancelled_allowed: false,
                                mifare_allowed: false,
                                club_allowed: false,
                                integration_allowed: false,
                                voucher_allowed: false,
                                aarskort_allowed: false,
                                loyaltyuser_allowed: false,
                                mdbprize_allowed: false,
                                csv_allowed: false,
                                measurings_allowed: false,
                                pdf_certifikat: false,
                                email_notification: false,
                                loyalty_products_allowed: false,
                                pos_access: false
                            };
                        }, () => {
                            localStorage.setItem('currentUser', JSON.stringify(this.currentUser));
                            // return true to indicate successful login
                            return true;
                        });
                    } else {
                        localStorage.setItem('currentUser', JSON.stringify(this.currentUser));
                    }
                } else {
                    // return false to indicate failed login
                    return false;
                }
            }));
    }

    getToken(): string {
        const currentUser = JSON.parse(localStorage.getItem('currentUser'));
        return currentUser && currentUser.token;
    }

    public isAdmin(): boolean {
        const currentUser = JSON.parse(localStorage.getItem('currentUser'));
        const role = currentUser && currentUser.role;
        return role === 1;
    }

    public getCustomerId(): number {
        const currentUser = JSON.parse(localStorage.getItem('currentUser'));

        return currentUser && currentUser.customer_id;
    }

    logout(): void {
        this.currentUser = null;
        localStorage.removeItem('currentUser');
        localStorage.removeItem('origUser');
    }

    impersonate(user: UserModel): Observable<boolean> {
        const currentUser = JSON.parse(localStorage.getItem('currentUser'));
        this.origUser = currentUser;
        localStorage.setItem('origUser', JSON.stringify(currentUser));
        return this.getLogin(user.email);
    }

    stopImpersonate() {
        try {
            const originalUser = localStorage.getItem('origUser');
            localStorage.setItem('currentUser', originalUser);
            this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
            localStorage.removeItem('origUser');
            this.origUser = null;
            return true;
        } catch (exception) {
            console.log(exception);
            return false;
        }

    }

    isImpersonating(): boolean {
        const originalUser = JSON.parse(localStorage.getItem('origUser'));
        return !!originalUser;
    }
}
